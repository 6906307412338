//  Arabic
export default{
    form: {
        startSurveyButton: "لنبدأ",
        nextButton: "التالي",
        submitAnswerButton: "إرسال الإجابة",
        continueButton: "استمرار",
        finishButton: "إنهاء",
        headerPercentageCompletion: 'مكتمل',
        headerQuestionsCompleted: 'أسئلة',
        headerDefaultErrorMessage: 'أوه. حدث خطأ ما.',
        brandingPoweredBy: 'مدعوم من',
        brandingPromoWhySurvey: 'لماذا تستبيان إذا كان بإمكانك استخدام <b>{product}</b>؟',
        brandingPromoGetStarted: 'ابدأ اليوم!',
        submissionPending: "يتم إرسال نموذج الصوت الخاص بك. من فضلك لا تغلق نافذة المتصفح.",
        submissionCanCloseWindow: "تم إرسال نموذج الصوت الخاص بك بنجاح. يمكنك الآن إغلاق النافذة 👍",
        mediaAttachmentOverlayCloseButton: "إغلاق",
        expiredFormHeader: "شكرًا لتوقفك هنا!",
        expiredFormMessage: "يبدو أن هذا الاستبيان قد انتهت صلاحيته. يرجى التواصل مع منشئ نموذج الصوت لإبلاغه.",
        notPublishedFormHeader: "النموذج الصوتي غير منشور!",
        notPublishedFormMessage: "يبدو أن هذا الاستبيان لم يتم نشره. يرجى التواصل مع منشئ نموذج الصوت لإبلاغه.",
        notPublishedFormRetryButton: "حاول مرة أخرى",
        errorFormHeader: "أوه!",
        errorFormMessage: "قد يكون هناك خطأ ما. يرجى المحاولة مرة أخرى لاحقًا.",
        errorFormRetryButton: "حاول مرة أخرى",
        emptyFormHeader: "شكرًا لتوقفك هنا!",
        emptyFormMessage: "أوه! يبدو أن هذا النموذج فارغ حاليًا بدون أسئلة أو حقول للتعبئة. نعتذر عن الإزعاج. يرجى التواصل مع منشئ النموذج لإبلاغه بالمشكلة. شكرًا لتفهمك وتعاونك!",

        restoreFormHeader: "يبدو أن لديك نموذجًا قيد التقدم!",
        restoreFormMessage: "هل ترغب في المتابعة من حيث توقفت؟",
        restoreFormButton: "متابعة النموذج",
        restoreFormButtonNew: "ابدأ من جديد",

        audioPermissionHeader: "هل ترغب في الإجابة بالصوت؟",
        audioPermissionMessage: "سيسهل التحدث الإجابة بسرعة.",
        audioPermissionAcceptButton: "نعم، أريد",
        audioPermissionDenyButton: "لا، شكرًا",
        audioPermissionErrorHeader: "الميكروفون غير متاح",
        audioPermissionErrorMessage: "يرجى التحقق من أذونات المتصفح لتمكين الوصول إلى الميكروفون.",
        audioPermissionErrorButton: "حسنًا",
        audioRecorderFailedUpload: "فشل تحميل التسجيل",
        silenceDetectorError: "لا أستطيع سماعك. يرجى التحقق من تفضيلات الميكروفون. نصيحة: قد يكون الميكروفون الخارجي الخاص بك.",
        audioRecorderButtonRecord: "ابدأ التسجيل",
        audioRecorderButtonRecordMore: "سجل المزيد",
        voiceResponseSelectionMessage: "اختر كيفية الإجابة...",
        voiceResponseSelectionOrDivider: "أو",
        voiceResponseTextInputPlaceholder: "اكتب الإجابة",
        voiceResponseTextInputCharacterCounterMinMessage: "يرجى إدخال {min} حرفًا على الأقل",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} حرف (الحد الأدنى {min} حرف)",
        voiceResponseTextInputCharacterValidationMessage: "{count} حرف (الحد الأدنى {min} حرف)",

        // checkbox
        checkboxValidationTooFew: "يجب أن تختار على الأقل {min} خيار",
        checkboxValidationTooMany: "يجب أن تختار ما لا يزيد عن {max} خيار",
        checkboxNoneOfTheAboveOption: "لا شيء مما سبق",
        checkboxInvalidAnswer: "إجابة غير صحيحة.",

        // datepicker
        datePickerPlaceholder: "اختر التاريخ",

        // dropdown
        // email
        emailLabel: "البريد الإلكتروني",
        emailInvalid: "البريد الإلكتروني غير صالح.",
        // file-upload
        fileUploadPluginNameCamera: "كاميرا",
        fileUploadPluginNameCameraVideo: "سجل فيديو",
        fileUploadPluginScreenCast: "مشاركة الشاشة",
        fileUploadVideoImportFilesDefault: 'سجل أو ارفع فيديو عبر:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'اختر خيار تسجيل الفيديو:',
        fileUploadVideoImportFilesNoCamera: 'اضغط زر لتسجيل فيديو للشاشة',
        fileUploadVideoImportFilesNoScreenCast: 'اضغط زر لتسجيل فيديو',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'لا توجد خيارات متاحة لتسجيل فيديو',

        // matrix
        matrixValidationMessage: "الإجابة صحيحة.",
        matrixRow: "صف",
        matrixColumn: "عمود",
        matrixRowRequired: "الصف {rowTitle} مطلوب.",
        matrixRadioGroup: "مجموعة الاختيار",
        matrixCheckboxGroup: "مجموعة التحديد",
        matrixGroupRequired: "للصف {rowTitle}. {type} {groupTitle} مطلوب.",
        matrixColumnRequired: "للصف {rowTitle}. العمود {columnTitle} مطلوب.",
        matrixColumnInvalid: "للصف {rowTitle}. العمود {columnTitle} غير صحيح.",
        matrixRequired: "مطلوب.",
        matrixNumericMustBeNumber: "يجب أن يكون رقمًا.",
        matrixNumericMustBeGreaterThenMin: "يجب أن يكون أكبر من {min}.",
        matrixNumericMustBeLessThenMax: "يجب أن يكون أقل من {max}.",
        matrixNumericMustBeInteger: "يجب أن يكون عددًا صحيحًا.",
        matrixNumericInvalidNumber: "رقم غير صالح.",

        // name
        nameLabel: "الاسم",
        nameInvalid: "حدد الاسم الأول واسم العائلة.",

        // nps
        npsNotLikely: "غير محتمل على الإطلاق",
        npsExtremelyLikely: "محتمل للغاية",

        // numeric input
        numericInputRequired: "مطلوب.",
        numericInputMustBeNumber: "يجب أن يكون رقمًا.",
        numericInputMustBeGreaterThenMin: "يجب أن يكون أكبر من {min}.",
        numericInputMustBeLessThenMax: "يجب أن يكون أقل من {max}.",
        numericInputMustBeInteger: "يجب أن يكون عددًا صحيحًا.",
        numericInputInvalidNumber: "رقم غير صالح.",
        numericInputPlaceholder: "اكتب الرقم",

        // phone
        phoneInvalid: "رقم الهاتف غير صالح.",
        phoneCountrySelectorLabel: 'رمز الدولة',
        phoneCountrySelectorError: 'اختر الدولة',
        phoneNumberLabel: 'رقم الهاتف',
        phoneExample: 'مثال:',

        // boolean
        booleanYesLabel: "نعم",
        booleanNoLabel: "لا",

        //questionStep
        questionStepAudioQuestionLabel: "سؤال صوتي",

        // errors
        invalidPhoneNumber: "{phone} هو رقم هاتف غير صالح.",
        invalidEmail: "{email} هو عنوان بريد إلكتروني غير صالح.",
        questionIsRequired: "السؤال مطلوب.",
        answerIsNotValid: "الإجابة غير صحيحة.",
        unfinishedProbingDialogError: "يرجى إنهاء الحوار.",
        cannotResumePartialResponse: "لا يمكن استئناف الإجابة الجزئية.",
        failedToSubmitForm: "فشل إرسال النموذج. يرجى التحقق من اتصال الإنترنت والمحاولة مرة أخرى."
    }
}
